import styled from "styled-components";

export const BackDrop = styled.div`
width: 100%;
  height: 180vh;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 7%;
  z-index: 1;
  padding-bottom: 30px;
`